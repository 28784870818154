import jwt_decode from 'jwt-decode';
import Vue from 'vue';
import Router from 'vue-router';

// Routes
import adminRoutes from './routes/admin';
import editorRoutes from './routes/editor';
import folderRoutes from './routes/folder';
import inspectorRoutes from './routes/inspector';
import publisherRoutes from './routes/publisher';
import reportRoutes from './routes/report';

// Single components
import NotFound from './components/layout/404';
import Callback from './components/layout/Callback';
import Dashboard from './components/layout/Dashboard';
import FleetData from './components/layout/FleetData';
import Login from './components/layout/Login';
import Logout from './components/layout/Logout';
import Profile from './components/layout/Profile';
import Version from './components/layout/Version';
import NewUserWelcomePage from './components/layout/NewUserWelcomePage';

import LinkRedirect from './components/layout/LinkRedirect';

import ImageUpload from './components/ImageUpload/Index';

import APIDocs from './views/api-docs';

import store from './store';

import { acquireToken } from './services/auth';

Vue.use(Router);

const lockKeepAlive = (taskNumber) => {
  store.dispatch('lockTask', taskNumber);
};

// Variable to store the timer for the lock keepalive while active.
let lockKeepAliveTimer;

const router = new Router({
  mode: 'history',
  routes: [
    ...editorRoutes,
    ...publisherRoutes,
    ...inspectorRoutes,
    ...reportRoutes,
    ...adminRoutes,
    ...folderRoutes,

    {
      path: '/view/profile',
      name: 'my-profile',
      component: Profile,
      meta: { authRequired: true },
    },
    {
      path: '/view/fleetdata',
      name: 'fleet-data',
      component: FleetData,
      meta: { authRequired: true },
    },
    {
      path: '/view/404',
      name: '404',
      component: NotFound,
    },
    // { path: '*', component: NotFound },
    {
      path: '/new-user-welcome-page',
      component: NewUserWelcomePage,
      meta: { authRequired: true },
    },
    { path: '/signin', component: Login },
    { path: '/logout', name: 'logout', component: Logout },
    {
      path: '/view/dashboard',
      alias: '/',
      component: Dashboard,
      meta: { authRequired: true },
    },
    {
      path: '/view/version',
      component: Version,
      meta: { authRequired: true },
    },
    { path: '/callback', name: 'callback-route', component: Callback },

    { path: '/view/image/upload', component: ImageUpload },
    {
      path: '/api/v1/link/:id',
      component: LinkRedirect,
      meta: { authRequired: true },
    },

    { path: '/view/api-docs', component: APIDocs },
  ],
});

router.beforeEach(async (to, from, next) => {
  // If the route required authentication, then make sure the user is logged in
  const requiredAuthentication = to.matched.some(
    (record) => record.meta.authRequired
  );
  // For polymer endpoints, make sure the token is valid for the maximum amount of time
  const shouldRefreshToken = to.matched.some(
    (record) => record.meta.refreshToken
  );
  if (requiredAuthentication) {
    let accessToken;
    try {
      accessToken = await acquireToken(shouldRefreshToken);
    } catch (e) {
      accessToken = null;
    }
    let isAccessTokenValid = false;
    if (accessToken) {
      const decodedAccessToken = jwt_decode(accessToken);
      isAccessTokenValid =
        decodedAccessToken.exp * 1000 - new Date().getTime() > 0;
    }
    if (isAccessTokenValid) {
      if (!store.state.currentUser) {
        await store.dispatch('getCurrentUserProfile');
      }
      const { currentUser } = store.state;

      // Validate user comming from OIL
      const isOilUser = currentUser._id === process.env.VUE_APP_OIL_USER_EMAIL;
      const hasLink = to.path.includes('link') || from.path.includes('link');
      const hasToken =
        to.query.token &&
        to.query.token !== '' &&
        from.query.token &&
        from.query.token !== '';
      if (isOilUser && !hasLink && !hasToken) {
        return next('/logout');
      }
      if (isOilUser && hasToken) {
        try {
          // Check if token is valid
          const decoded = await jwt_decode(to.query.token);
          const isTokenValid = decoded.exp * 1000 - Date.now() > 0;
          if (!isTokenValid) {
            return next('/logout');
          }
        } catch (e) {
          console.error('Failed to validate OIL token.', e);
          return next('/logout');
        }
      }

      const isPendingApprovalOrRejected =
        currentUser.isPendingApproval === true ||
        currentUser.isRejected === true;

      // If the user is pending approval or rejected
      // and loading something other than the welcome page,
      // send to the welcome page
      if (
        isPendingApprovalOrRejected &&
        to.fullPath !== '/new-user-welcome-page'
      ) {
        return next('/new-user-welcome-page');
      }

      // If the user is approved and loading the welcome page,
      // send to the dashboard
      if (
        !isPendingApprovalOrRejected &&
        to.fullPath === '/new-user-welcome-page'
      ) {
        return next('/view/dashboard');
      }

      // Make sure the user has permission for the route he's trying to view
      const splittedPath = to.path.split('/');
      const isAccessingTool = splittedPath.length > 2;
      if (isAccessingTool) {
        const tool = splittedPath[2];
        const hasPermission =
          ['dashboard', 'profile', 'version', 'fleetdata'].includes(tool) ||
          store.state.currentUserAllowedTools.some(
            (allowedTools) => allowedTools === tool
          );
        if (!hasPermission) {
          return next('/404');
        }
      }
    } else {
      localStorage.setItem('path', to.path);
      return next({ path: '/signin' });
    }
  }

  // Task locking
  // Make sure when editing the task we attempt to lock it first
  // Make sure when finishing editing the task we release the lock
  // Failing to lock should be handled by the component and enabling read-only mode
  // TODO: move this to the vue.js component once this is polymer migration is finished
  // For now we keep the locking mechanism here to simplify
  const needsTaskUnlock = from.meta.needsTaskLock;
  const { needsTaskLock } = to.meta;

  // Lock when navigating to the edit page and unlock when leaving the edit page
  if (needsTaskUnlock) {
    try {
      // If timer is active remove it
      if (lockKeepAliveTimer) {
        clearInterval(lockKeepAliveTimer);
        lockKeepAliveTimer = null;
      }
      await store.dispatch('unlockTask', from.params.taskNumber);
    } catch (e) {
      console.warn('Failed to unlock');
    }
  } else if (needsTaskLock) {
    try {
      // If timer is active remove it
      if (lockKeepAliveTimer) {
        clearInterval(lockKeepAliveTimer);
      }
      await store.dispatch('lockTask', to.params.taskNumber);
      // Create timer to refresh lock
      lockKeepAliveTimer = setInterval(
        lockKeepAlive,
        1000 * 60,
        to.params.taskNumber
      );
    } catch (e) {
      console.warn('Failed to lock');
    }
  }

  next();
});

window.vueRouter = router;

export default router;
