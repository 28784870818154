<template>
  <div class="dij-diagrambuilder-keypad">
    <b-modal
      v-if="isCameraDisplayed"
      :active="true"
      class="dij-diagrambuilder-camera-modal"
      full-screen
    >
      <camera @cancel="hideCamera" @ok="processCamera" />
    </b-modal>
    <b-modal v-if="isAnnotatorDisplayed" :active="true">
      <image-annotator
        :image="imageToBeAnnotated"
        @cancel="hideAnnotator"
        @ok="processAnnotator"
      />
    </b-modal>
    <image-gallery
      v-if="isGalleryDisplayed"
      v-model="inspection.images"
      @close="hideGallery"
      @onEditInformation="$emit('forceSaveInspection')"
    />
    <b-modal
      v-if="isCommentDisplayed"
      :active="true"
      has-modal-card
      trap-focus
      class="dij-diagrambuilder-camera-modal"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Comment</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Comment">
            <b-input
              type="textarea"
              v-model="inspection.inspectorComment"
              placeholder="Your comment"
            >
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="hideComment()" />
        </footer>
      </div>
    </b-modal>
    <div class="dij-diagrambuilder-keypad-header">
      <div
        class="dij-diagrambuilder-keypad-header-type"
        :style="inspection.type === 'generic' ? 'flex-direction: column' : ''"
      >
        <div>{{ inspection.number }}. {{ $t(inspection.type) }}</div>
        <div>
          <template v-if="isRunoutWithOneMeasurementOnMeasurementZero()">
            {{ $t('tir') }}
          </template>
          <template v-else-if="isRunoutWithOneMeasurementOnMeasurementOne()">
            {{ $t('position') }}
          </template>
          <template v-else>
            <template v-if="inspection.type !== 'freefinding'">
              <template v-if="inspection.type === 'generic'">
                {{ inspection.measurementName }}:
              </template>

              <template v-else>
                <template v-if="inspection.type !== 'visual'">
                  {{ $t('position') }}
                  {{ inspection.selectedMeasurementNumber + 1 }}
                </template>
              </template>
            </template>
          </template>
        </div>
      </div>
      <div v-if="shouldDisplayTolerances()">
        Tol: {{ getInspectionToleranceSign(inspection).first
        }}{{ inspection.positiveTolerance }}
        {{ getInspectionToleranceSign(inspection).second
        }}{{ inspection.negativeTolerance }}
      </div>
      <div v-if="shouldDisplayExpected()">
        Exp: {{ inspection.expectedValue }}
      </div>
      <div
        class="dij-diagrambuilder-keypad-value"
        v-if="shouldDisplayKeypadValue()"
      >
        <template v-if="shouldDisplayNumbersPad()">
          <input
            type="text"
            @input="restrictToNumeric"
            v-model="
              inspection.measurements[inspection.selectedMeasurementNumber]
                .value
            "
          />
        </template>
        <template v-else>
          <input
            type="text"
            v-model="
              inspection.measurements[inspection.selectedMeasurementNumber]
                .value
            "
          />
        </template>
        <template v-if="isRunoutWithOneMeasurementOnMeasurementOne()">
          {{ $t(inspection.direction) }}
        </template>
        <template v-else>
          <template v-if="inspection.type !== 'generic'">
            {{ $t(inspection.unit) }}
          </template>
        </template>
      </div>
    </div>
    <template v-if="shouldDisplayNumbersPad()">
      <div class="dij-diagrambuilder-keypad-numbers">
        <div data-value="7">7</div>
        <div data-value="8">8</div>
        <div data-value="9">9</div>
        <div data-value="4">4</div>
        <div data-value="5">5</div>
        <div data-value="6">6</div>
        <div data-value="1">1</div>
        <div data-value="2">2</div>
        <div data-value="3">3</div>
        <div data-value="delete">←</div>
        <div data-value="0">0</div>
        <div data-value="dot">.</div>
      </div>
      <div class="dij-diagrambuilder-keypad-signs">
        <div data-value="plus">+</div>
        <div data-value="minus">-</div>
      </div>
    </template>
    <template v-else-if="shouldDisplayVisualConditions()">
      <b-field :label="$t('all')">
        <div class="dij-diagrambuilder-keypad-visual-option">
          <b-button
            style="margin-left: 5px; margin-right: 5px"
            expanded
            :type="
              isMeasurementAcceptable(inspection) ? 'is-success' : 'is-normal'
            "
            @click="handleAcceptableCondition"
          >
            <div style="display: flex">
              <b-icon icon="check"></b-icon>
              <span>{{ $t('acceptable') }}</span>
            </div>
          </b-button>
        </div>
      </b-field>
      <b-field :label="$t('rubbed')">
        <div class="dij-diagrambuilder-keypad-visual-option">
          <b-radio-button
            v-model="inspection.measurements[0].value"
            native-value="high"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('high') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[0].value"
            native-value="medium"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('medium') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[0].value"
            native-value="low"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('low') }}</span>
          </b-radio-button>
        </div>
      </b-field>
      <b-field :label="$t('damaged')">
        <div class="dij-diagrambuilder-keypad-visual-option">
          <b-radio-button
            v-model="inspection.measurements[1].value"
            native-value="high"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('high') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[1].value"
            native-value="medium"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('medium') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[1].value"
            native-value="low"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('low') }}</span>
          </b-radio-button>
        </div>
      </b-field>
      <b-field :label="$t('pitted')">
        <div class="dij-diagrambuilder-keypad-visual-option">
          <b-radio-button
            v-model="inspection.measurements[2].value"
            native-value="high"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('high') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[2].value"
            native-value="medium"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('medium') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[2].value"
            native-value="low"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('low') }}</span>
          </b-radio-button>
        </div>
      </b-field>
      <b-field :label="$t('eroded')">
        <div class="dij-diagrambuilder-keypad-visual-option">
          <b-radio-button
            v-model="inspection.measurements[3].value"
            native-value="high"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('high') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[3].value"
            native-value="medium"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('medium') }}</span>
          </b-radio-button>
          <b-radio-button
            v-model="inspection.measurements[3].value"
            native-value="low"
            @input="handleNonacceptableCondition"
          >
            <span>{{ $t('low') }}</span>
          </b-radio-button>
        </div>
      </b-field>
    </template>
    <div class="dij-diagrambuilder-keypad-attachments">
      <div class="dij-diagrambuilder-file-chooser">
        <input
          class="mdi mdi-folder mdi-48px"
          ref="imageUpload"
          type="file"
          multiple
          accept="image/*;capture=camera"
          @change="handleFileChooserChange"
        />
      </div>
      <div class="dij-diagrambuilder-camera">
        <b-icon icon="camera" size="is-large" @click.native="showCamera()" />
      </div>
      <div class="dij-diagrambuilder-gallery">
        <b-icon
          icon="image"
          size="is-large"
          :class="
            getImages(inspection) && getImages(inspection).length > 0
              ? 'dij-diagrambuilder-gallery-button'
              : 'dij-diagrambuilder-gallery-button disabled'
          "
          @click.native="showGallery()"
        />
        <b-tag
          rounded
          type="is-info"
          v-if="getImages(inspection) && getImages(inspection).length > 0"
          @click.native="showGallery()"
        >
          {{ getImages(inspection).length }}</b-tag
        >
      </div>
      <div>
        <b-icon
          icon="comment-text-outline"
          size="is-large"
          @click.native="showComment()"
        />
      </div>
    </div>
    <div class="dij-diagrambuilder-keypad-actions">
      <b-button
        v-if="inspection.type === 'freefinding'"
        expanded
        type="is-danger"
        @click="deleteInspection()"
      >
        {{ $t('delete') }}
      </b-button>
      <b-button expanded type="is-primary" @click="done()">
        {{ $t('done') }}
      </b-button>
      <b-button
        expanded
        v-if="!isLastMeasurement()"
        type="is-success"
        @click="next"
      >
        {{ $t('next') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { v4 } from 'uuid';
import Camera from '../layout/Camera';
import ImageAnnotator from '../layout/ImageAnnotator';
import ImageGallery from '../layout/ImageGallery';
import imageHelper from '../../../public/codebase/imageFunctions';
import DIJVueConfigurations from '../../helpers/DIJVueConfigurations';

import { GET_CURRENT_JOB } from '../../store/jobs/getters/getterTypes';
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';
import { GET_CURRENT_USER } from '../../store/users/getters/getterTypes';

const uuidv4 = v4;

export default {
  name: 'inspection-keypad',
  props: ['value'],
  emits: ['next', 'done'],
  data() {
    return {
      uploadedFiles: 0,
      totalFilesToBeUploaded: 0,
      multipleImageUploaderToast: null,
      numberOfDecimalPlaces: 0,
      isCameraDisplayed: false,
      isAnnotatorDisplayed: false,
      isGalleryDisplayed: false,
      isCommentDisplayed: false,
      imageToBeAnnotated: null,
      visualConditionAll: null,
    };
  },
  components: { Camera, ImageAnnotator, ImageGallery },
  computed: {
    inspection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    job() {
      return this.$store.getters[GET_CURRENT_JOB];
    },
    task() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    user() {
      return this.$store.getters[GET_CURRENT_USER];
    },
  },
  created() {
    const { expectedValue } = this.inspection;

    this.numberOfDecimalPlaces =
      expectedValue &&
      Number.isFinite(expectedValue) &&
      !Number.isInteger(expectedValue)
        ? expectedValue.toString().split('.')[1].length
        : 0;

    if (this.inspection.type === 'visual') {
      if (
        this.inspection.measurements[0].value === 'ok' &&
        this.inspection.measurements[1].value === 'ok' &&
        this.inspection.measurements[2].value === 'ok' &&
        this.inspection.measurements[3].value === 'ok'
      ) {
        this.visualConditionAll = 'ok';
      }
    }
  },
  mounted() {
    if (this.shouldDisplayNumbersPad()) {
      const keyElements = document.querySelectorAll(
        '.dij-diagrambuilder-keypad-numbers div'
      );

      keyElements.forEach((keyElement) => {
        keyElement.addEventListener('click', () => {
          const { value } = keyElement.dataset;
          const measurement =
            this.inspection.measurements[
              this.inspection.selectedMeasurementNumber
            ];
          if (value === 'delete') {
            measurement.value = measurement.value.slice(0, -1);
            // If the only character remaining is the '-' then remove it as well
            if (measurement.value === '-') {
              measurement.value = '';
            }
          } else if (value === 'dot') {
            // If the value is empty, add '0.'
            // and only allow one '.' character
            if (measurement.value === '') {
              measurement.value = '0.';
            } else if (measurement.value.indexOf('.') < 0) {
              measurement.value += '.';
            }
          } else if (value === '0' && measurement.value !== '0') {
            // Allow adding a 0 if value is not '0'
            measurement.value += '0';
          } else if (measurement.value === '0') {
            // If the value is '0' replace with pressed character
            measurement.value = value;
          } else {
            measurement.value += value;
          }
          this.refreshUI();
        });
      });

      const positiveElement = document.querySelector(
        '.dij-diagrambuilder-keypad-signs div:first-child'
      );
      positiveElement.addEventListener('click', () => {
        const measurement =
          this.inspection.measurements[
            this.inspection.selectedMeasurementNumber
          ];
        if (measurement.value) {
          measurement.value = Math.abs(measurement.value).toString();
        } else {
          measurement.value = '';
        }
        this.refreshUI();
      });

      const negativeElement = document.querySelector(
        '.dij-diagrambuilder-keypad-signs div:last-child'
      );

      negativeElement.addEventListener('click', () => {
        const measurement =
          this.inspection.measurements[
            this.inspection.selectedMeasurementNumber
          ];
        if (measurement.value) {
          measurement.value = (-Math.abs(measurement.value)).toString();
        } else {
          measurement.value = '';
        }

        this.refreshUI();
      });
    }
  },
  watch: {
    'inspection.measurements': {
      handler() {
        this.inspection.updateBy = this.user.fullname;
        this.inspection.updateAt = Date.now();
      },
      deep: true,
    },
  },
  methods: {
    getImages(inspection) {
      // inspection.measurements[getMeasurementNumber()].images
      return inspection.images;
    },
    getInspectionToleranceSign(inspection) {
      let first = '+';
      let second = '-';

      switch (inspection.toleranceType) {
        case 'positive':
          second = '+';
          break;
        case 'negative':
          first = '-';
          break;
        default:
          break;
      }

      return { first, second };
    },
    isMeasurementAcceptable(inspection) {
      const isMeasurementAcceptable = inspection.measurements
        .slice(0, 4)
        .every((measurement) => measurement.value === '');

      if (isMeasurementAcceptable) {
        this.visualConditionAll = 'ok';
      }

      return isMeasurementAcceptable;
    },

    getMeasurementNumber() {
      if (this.inspection.type === 'visual') {
        return 3; // 3 means the index for the measurements of the visual inspection
      }

      return this.inspection.selectedMeasurementNumber;
    },
    restrictToNumeric($event) {
      let { value } = $event.target;
      if (this.inspection.type !== 'generic') {
        value = value.replace(/[^0-9.]/g, '');
        const parts = value.split('.').filter(Boolean);
        if (parts.length > 1) {
          value = `${parts.shift()}.${parts.join('')}`;
        }
      }

      this.inspection.measurements[
        this.inspection.selectedMeasurementNumber
      ].value = value;
    },
    showCamera() {
      this.isCameraDisplayed = true;
    },
    hideCamera() {
      this.isCameraDisplayed = false;
    },
    processCamera(image) {
      this.hideCamera();
      this.imageToBeAnnotated = image;
      this.showAnnotator();
      this.filename = `${uuidv4()}.jpg`;
    },
    showGallery() {
      if (
        this.getImages(this.inspection) &&
        this.getImages(this.inspection).length > 0
      ) {
        this.isGalleryDisplayed = true;
      }
    },
    hideGallery() {
      this.isGalleryDisplayed = false;
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    async handleFileChooserChange(event) {
      // User clicked cancel
      if (event.target.files.length === 0) {
        return;
      }

      let showAnnotator = false;

      if (
        event.target.files.length === 1 &&
        event.target.files[0].type !== 'application/pdf'
      ) {
        showAnnotator = true;
      }

      if (showAnnotator) {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const image = new Image();
          image.onload = () => {
            this.imageToBeAnnotated = image;
            this.showAnnotator();
          };
          image.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
        this.filename = `${uuidv4()}.jpg`;
      } else {
        this.multipleImageUploaderToast = this.$buefy.toast.open({
          duration: 50000,
          message: 'Preparing files to be uploaded',
          type: 'is-warning',
          position: 'is-bottom',
        });

        const { files } = event.target;
        this.uploadedFiles = 0;
        this.totalFilesToBeUploaded = files.length;
        this.multipleImageUploaderToast.message = `Uploading images ${this.uploadedFiles} / ${this.totalFilesToBeUploaded}`;

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (file.type === 'application/pdf') {
            // eslint-disable-next-line no-await-in-loop
            const dataURL = await this.handlePDF(file);
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL
              .split(',')[0]
              .split(':')[1]
              .split(';')[0];
            const byteNumbers = new Array(byteString.length);

            for (let j = 0; j < byteString.length; j++) {
              byteNumbers[j] = byteString.charCodeAt(j);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeString });
            const fileJsonName = `${uuidv4()}_pdf.png`;
            const fileJson = new File([blob], fileJsonName);

            const workOrder = this.job.name;
            const site = this.user.location;
            const path = `uploads/sites/${site}/${workOrder}/${this.task._id}`;
            // eslint-disable-next-line no-await-in-loop
            const url = await this.uploadToS3(fileJson, path);
            if (!this.inspection.images) {
              this.inspection.images = [];
            }
            this.inspection.images.push({
              description: '',
              name: '',
              url,
            });
          } else {
            const workOrder = this.job.name;
            const site = this.user.location;
            const path = `uploads/sites/${site}/${workOrder}/${this.task._id}`;

            // eslint-disable-next-line no-await-in-loop
            const url = await this.uploadToS3(file, path);
            if (!this.inspection.images) {
              this.inspection.images = [];
            }
            this.inspection.images.push({
              description: '',
              name: '',
              url,
            });
          }

          this.uploadedFiles++;
          this.multipleImageUploaderToast.message = `Uploading images ${this.uploadedFiles} / ${this.totalFilesToBeUploaded}`;

          if (this.uploadedFiles === this.totalFilesToBeUploaded) {
            setTimeout(() => {
              if (this.multipleImageUploaderToast) {
                this.multipleImageUploaderToast.close();
                this.multipleImageUploaderToast = null;
              }
            }, 2000);
          }
        }

        this.$emit('forceSaveInspection');
      }

      // Clear the file list to allow the user to repeat the upload
      event.target.value = '';
    },

    // Very ugly workaround untill we move the logic to use pdf on backend
    async handlePDF(file) {
      const { pdfjsLib } = window;
      pdfjsLib.GlobalWorkerOptions.workerSrc = null;
      const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;

      // eslint-disable-next-line
      const page = await pdf.getPage(1);
      const scale = 1;
      const viewport = page.getViewport(scale);
      // Prepare canvas using PDF page dimensions
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      // eslint-disable-next-line
      await page.render({
        canvasContext: context,
        viewport,
      });

      return canvas.toDataURL();
    },
    showAnnotator() {
      this.isAnnotatorDisplayed = true;
    },
    hideAnnotator() {
      this.isAnnotatorDisplayed = false;
      this.imageToBeAnnotated = null;
    },
    async processAnnotator(image) {
      this.hideAnnotator();

      const fileName = `${uuidv4()}.jpg`;
      const canvas = document.createElement('canvas');
      imageHelper.drawImageFor1920px(canvas, image);
      const file = this.dataURLtoFile(
        canvas.toDataURL('image/jpeg', 0.9),
        fileName
      );
      const workOrder = this.job.name;
      const site = this.user.location;
      const path = `uploads/sites/${site}/${workOrder}/${this.task._id}`;

      // Uploaded images per measurement use this when we agreed that workflow
      // this.uploadToS3(file, path).then((url) => {
      //   const measurementNumber = this.getMeasurementNumber();

      //   if (!this.inspection.measurements[measurementNumber].images) {
      //     this.inspection.measurements.images = [];
      //   }

      //   this.inspection.measurements[measurementNumber].images.push({
      //     name: '',
      //     url,
      //   });
      //   this.$emit('forceSaveInspection');
      // });
      this.uploadToS3(file, path).then((url) => {
        if (!this.inspection.images) {
          this.inspection.images = [];
        }

        this.inspection.images.push({
          description: '',
          name: '',
          url,
        });
        this.$emit('forceSaveInspection');
      });
    },
    showComment() {
      this.isCommentDisplayed = true;
    },
    hideComment() {
      this.isCommentDisplayed = false;
      this.$emit('forceSaveInspection');
    },
    shouldDisplayNumbersPad() {
      return ['length', 'generic', 'diameter', 'runout'].includes(
        this.inspection.type
      );
    },
    shouldDisplayKeypadValue() {
      return !['visual', 'freefinding'].includes(this.inspection.type);
    },
    shouldDisplayExpected() {
      let shouldDisplay = false;
      if (this.inspection.expectedValue !== null) {
        shouldDisplay = true;
      }

      if (
        ['visual', 'freefinding', 'runout', 'generic'].includes(
          this.inspection.type
        )
      ) {
        shouldDisplay = false;
      }

      return shouldDisplay;
    },
    shouldDisplayTolerances() {
      return (
        this.inspection.type === 'length' || this.inspection.type === 'diameter'
      );
    },
    shouldDisplayVisualConditions() {
      return this.inspection.type === 'visual';
    },
    isLastMeasurement() {
      return (
        this.inspection.selectedMeasurementNumber ===
        this.inspection.measurementCount - 1
      );
    },
    done() {
      const measurement =
        this.inspection.measurements[this.inspection.selectedMeasurementNumber];
      // If the user tries to save the value '<number>.'
      // remove the '.'
      if (measurement.value[measurement.value.length - 1] === '.') {
        measurement.value = measurement.value.slice(0, -1);
      }
      this.$emit('done');
      this.refreshUI();
    },
    deleteInspection() {
      this.$emit('deleteInspection');
      this.refreshUI();
    },
    next() {
      this.inspection.selectedMeasurementNumber++;
      this.$emit('next');
      this.refreshUI();
    },
    refreshUI() {
      this.inspection.measurements = [...this.inspection.measurements];
    },
    handleAcceptableCondition() {
      // Toggle 'ok' status
      this.visualConditionAll = this.visualConditionAll === 'ok' ? '' : 'ok';

      // If the acceptable option is selected, propagate the value to all conditions
      if (this.visualConditionAll === 'ok') {
        this.inspection.measurements.forEach((measurement) => {
          measurement.value = ''; // Reset or clear the value
        });
      } else {
        this.inspection.measurements.forEach((measurement) => {
          measurement.value = 'notCompleted'; // Set all to 'notCompleted'
        });
      }
    },

    handleNonacceptableCondition() {
      // Every time the user presses an option diferent from "acceptable",
      // clear the visualConditionAll
      this.visualConditionAll = '';
    },
    async uploadToS3(file, path) {
      const dijConfigurations = new DIJVueConfigurations();
      const url = await dijConfigurations.uploadFileToS3(file, file.name, path);
      return url;
    },
    isRunoutWithOneMeasurementOnMeasurementZero() {
      return (
        this.inspection.type === 'runout' &&
        this.inspection.selectedMeasurementNumber === 0 &&
        !this.inspection.multipleMeasurements
      );
    },
    isRunoutWithOneMeasurementOnMeasurementOne() {
      return (
        this.inspection.type === 'runout' &&
        this.inspection.selectedMeasurementNumber === 1 &&
        !this.inspection.multipleMeasurements
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-diagrambuilder-keypad-numbers {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  cursor: pointer;

  div {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: 1px solid lightgray;
  }
}
.dij-diagrambuilder-keypad-signs {
  display: flex;
  cursor: pointer;

  div {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: 1px solid lightgray;
  }
}
.dij-diagrambuilder-keypad-attachments {
  display: flex;
  cursor: pointer;

  div {
    flex: 1 1 auto;
    color: gray;

    border: 1px solid lightgray;
  }

  .disabled {
    color: lightgray;
  }

  .dij-diagrambuilder-gallery {
    position: relative;

    span.tag {
      position: absolute;
      top: -4px;
      right: 2px;
    }
  }
}
.dij-diagrambuilder-keypad-actions {
  display: flex;
  gap: 5px;
  div {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
}
.dij-diagrambuilder-keypad-header-type {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.modal-card-foot {
  justify-content: flex-end;
}
.dij-diagrambuilder-keypad-value {
  display: flex;
  justify-content: flex-end;
  margin: 5px;

  input {
    width: 100%;
    background-color: unset;
    border: 0;
  }
}
.dij-diagrambuilder-keypad-visual-option {
  display: flex;

  div {
    flex: 1 1 auto;
  }
}
.dij-diagrambuilder-file-chooser input[type='file'] {
  width: 48px;
  height: 48px;
  overflow: hidden;
}
@media only screen and (max-width: 1080px) and (max-height: 810px),
  only screen and (max-width: 810px) and (max-height: 1080px) {
  .dij-diagrambuilder-file-chooser input[type='file'] {
    &::before {
      content: '\F0100';
    }
  }
  .dij-diagrambuilder-camera {
    display: none;
  }
}
// Disable the input number arrows
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}
</style>
