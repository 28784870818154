var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"tool-bar"}},[_c('div',{attrs:{"id":"report-header"}},[_c('div',{staticClass:"back-button",on:{"click":_vm.onBackButtonClick}},[_c('img',{attrs:{"src":"/img/icons/back_arrow.png"}})]),_c('div',{staticClass:"breadcrumb-outer"},[_c('span',{staticClass:"breadcrumb-text",attrs:{"hidden":this.$route.params.token || this.user._id === this.oilUser,"id":"gotoProductList"},on:{"click":_vm.gotoProductList}},[_vm._v(" "+_vm._s(_vm.$t('work_order_list'))+" ")]),_c('span',{staticClass:"divider-text"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.jobWorkName)+" "),_c('span',{staticClass:"divider-text"},[_vm._v("/")]),_c('b-dropdown',{attrs:{"aria-role":"list","close-on-click":true,"scrollable":true,"max-height":500},on:{"change":_vm.onChangeItem},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('div',{staticClass:"container-select"},[_c('div',{staticClass:"dropdown-input-container"},[_c('input',{staticClass:"dropdown-input",attrs:{"type":"text"},domProps:{"value":_vm.dropdownInputText},on:{"mousedown":_vm.preventMouseDown}}),_c('b-icon',{attrs:{"icon":active ? 'menu-up' : 'menu-down',"size":"is-small"}})],1)])]}}]),model:{value:(_vm.selectedTask),callback:function ($$v) {_vm.selectedTask=$$v},expression:"selectedTask"}},[_vm._l((_vm.dropDownTasks),function(task){return [(task.taskType !== 'folder')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","value":task._id,"id":"task-dropdown-item"}},[_c('div',{class:_vm.isTaskExclude(task._id) && 'excluded',style:({
                'padding-left': _vm.getIndentationLevel(task.folderLevel),
              })},[(_vm.enableExcludeItems)?_c('b-checkbox',{attrs:{"value":_vm.isTaskExclude(task._id)},on:{"input":(e) => _vm.onExcludeTask(e, task)},nativeOn:{"!click":function($event){return _vm.stopPropagation.apply(null, arguments)}}}):_vm._e(),_c('b-icon',{attrs:{"icon":task.taskType === 'instruction'
                    ? 'format-list-bulleted'
                    : 'wrench'}}),_vm._v(" "+_vm._s(task.taskName)+" ")],1)]):_vm._e(),(task.taskType === 'folder')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","value":task._id,"id":"task-dropdown-item","disabled":true}},[_c('div',{style:({
                'padding-left': _vm.getIndentationLevel(task.folderLevel),
              })},[(_vm.enableExcludeItems)?_c('b-checkbox',{attrs:{"value":_vm.isTaskExclude(task._id)},on:{"input":(e) => _vm.onExcludeFolder(e, task._id)}}):_vm._e(),_c('b-icon',{attrs:{"icon":'folder'}}),_vm._v(" "+_vm._s(task.taskName)+" ")],1)]):_vm._e()]})],2)],1),_c('b-button',{staticClass:"header-button",attrs:{"size":"is-small","focused":_vm.isInternalReport},on:{"click":function($event){return _vm.onReportType(true)}}},[_vm._v("Internal Report")]),_c('b-button',{staticClass:"header-button",attrs:{"size":"is-small","focused":!_vm.isInternalReport},on:{"click":function($event){return _vm.onReportType(false)}}},[_vm._v("Customer Report")]),_c('b-button',{staticClass:"header-button",attrs:{"size":"is-small","focused":false},on:{"click":_vm.onExcludeItems}},[(_vm.enableExcludeItems)?[_vm._v(" Done ")]:[_vm._v("Exclude Items")]],2)],1),_c('div',{staticClass:"download-container"},[_c('div',[_c('b-dropdown',{attrs:{"aria-role":"list","id":"revision-dropdown","position":"is-bottom-left","scrollable":true,"max-height":500},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{attrs:{"icon-right":active ? 'menu-up' : 'menu-down'}},[_vm._v("Revisions")])]}}])},_vm._l((_vm.generatedReports),function(report){return _c('b-dropdown-item',{key:report.id,attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onDownloadGeneratedReport(report.id)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',[_vm._v("Description: "+_vm._s(report.revisionDescription))]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(_vm._s(report.fileName))]),_c('div',{staticClass:"column"},[_vm._v(_vm._s(_vm.parseDate(report.date)))])])])])])}),1)],1),_c('div',[_c('b-button',{attrs:{"size":"is-large","type":"is-info","disabled":_vm.disabledDownload,"loading":_vm.disabledDownload},on:{"click":_vm.onDownload}},[_vm._v(_vm._s('Download'))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }