/* eslint-disable lines-between-class-members */
class TaskDTO {
  _id;

  deleted;

  folderId;

  folderTitle;

  folderLevel;

  taskType;

  type;

  taskName;

  taskOrder;

  jobId;

  modifier;

  workStatusCode;

  hasSubfolder;

  thumbnailUrl;

  taskNumber;

  childConfig;

  skipTask;

  taskComment;

  reportNote;

  modifyStartTime;

  isEditable;

  inspectionTime;

  fleetDataModel;

  hasTabUnloaded;

  editingTime;

  date;

  deletedAt;

  deletedBy;

  name;

  parent_name;

  workOrderName;

  workOrderNumber;

  partNumber;

  userEnteredPartNumber;

  model;

  frameType;

  title;

  brand;

  documentNumber;

  revision;

  projectInfoText;

  customerInfoText;

  completenessPercentage;

  tagName;

  fields;

  cssText;

  classList;

  mtes;

  prodCreatedByUser;

  modifyTimeout;

  zoomLevel;

  children;

  diagram;

  checkOffline;

  beingModified;

  constructor(data) {
    this._id = data._id;
    this.deleted = data.deleted;
    this.folderId = data.folderId;
    this.folderTitle = data.folderTitle;
    this.folderLevel = data.folderLevel;
    this.taskType = data.taskType;
    this.type = data.type;
    this.taskName = data.taskName;
    this.taskOrder = data.taskOrder;
    this.jobId = data.jobId;
    this.modifier = data.modifier;
    this.workStatusCode = data.workStatusCode;
    this.hasSubfolder = data.hasSubfolder;
    this.thumbnailUrl = data.thumbnailUrl;
    this.taskNumber = data.taskNumber;
    this.childConfig = data.childConfig;
    this.skipTask = data.skipTask;
    this.taskComment = data.taskComment;
    this.reportNote = data.reportNote;
    this.modifyStartTime = data.modifyStartTime;
    this.isEditable = data.isEditable;
    this.inspectionTime = data.inspectionTime;
    this.fleetDataModel = data.fleetDataModel;
    this.hasTabUnloaded = data.hasTabUnloaded;
    this.editingTime = data.editingTime;
    this.date = data.date;
    this.deletedAt = data.deletedAt;
    this.deletedBy = data.deletedBy;
    this.name = data.name;
    this.parent_name = data.parent_name;
    this.workOrderName = data.workOrderName;
    this.workOrderNumber = data.workOrderNumber;
    this.partNumber = data.partNumber;
    this.userEnteredPartNumber = data.userEnteredPartNumber;
    this.model = data.model;
    this.frameType = data.frameType;
    this.title = data.title;
    this.brand = data.brand;
    this.documentNumber = data.documentNumber;
    this.revision = data.revision;
    this.projectInfoText = data.projectInfoText;
    this.customerInfoText = data.customerInfoText;
    this.completenessPercentage = data.completenessPercentage;
    this.tagName = data.tagName;
    this.fields = data.fields;
    this.cssText = data.cssText;
    this.classList = data.classList;
    this.mtes = data.mtes;
    this.prodCreatedByUser = data.prodCreatedByUser;
    this.modifyTimeout = data.modifyTimeout;
    this.zoomLevel = data.zoomLevel;
    this.children = data.children;
    this.diagram = data.diagram;
    this.checkOffline = data.checkOffline;
    this.beingModified = data.beingModified;
  }
}

module.exports = TaskDTO;
